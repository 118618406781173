import React, { useCallback, useState } from "react";
import { _currency_format } from "../../../../../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomButton from "../../../../../components/customButton/CustomButton";
import DateFrameInput from "../../../../../components/activity/components/dateFrameInput/DateFrameInput";
import TimeFrameInput from "../../../../../components/activity/components/timeFrameInput/TimeFrameInput";
import "./FillTimeSheet.css";

const FillTimeSheet = ({ values, setFieldValue, close }) => {
  const [sheetDetails, setSheetDetails] = useState({
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    hour: "",
    charge: "",
  });
  const [errors, setErrors] = useState({});

  const check = () => {
    const today = new Date();
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);
    let [hours1] = sheetDetails.time_from.split(":").map(Number);
    let [hours2] = sheetDetails.time_to.split(":").map(Number);

    const time = today.toLocaleTimeString();

    const [hour] = time.split(":").map(Number);
    let isPM = time.toLowerCase().includes("pm");
    const nowHour = isPM ? hour + 12 : hour;

    const newErrors = {};
    if (!sheetDetails.date_from.length) {
      newErrors.date_from = "Required";
    } else if (!sheetDetails.date_to.length) {
      newErrors.date_to = "Required";
    } else if (
      today.toISOString().split("T")[0] > dateFrom.toISOString().split("T")[0]
    ) {
      newErrors.date_from = "Today or future date";
    } else if (dateFrom > dateTo) {
      newErrors.date_to = "End date must be greater";
    } else if (
      today.toISOString().split("T")[0] ===
        dateFrom.toISOString().split("T")[0] &&
      nowHour >= hours1
    ) {
      newErrors.time_from =
        "Input must be greater by an hour";
    } else if (hours1 > hours2) {
      newErrors.time_to = "End must be greater";
    } else if (!sheetDetails.time_from.length) {
      newErrors.time_from = "Required";
    } else if (!sheetDetails.time_to.length) {
      newErrors.time_to = "Required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getHoursnCharge = () => {
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);

    const timeFrom = new Date(`1970-01-01T${sheetDetails.time_from}:00Z`);
    const timeTo = new Date(`1970-01-01T${sheetDetails.time_to}:00Z`);

    let diff_in_time = dateTo.getTime() - dateFrom.getTime();
    let diff_in_days = Math.round(diff_in_time / (1000 * 3600 * 24));

    let totalDays = diff_in_days + 1;
    const diffInMilliseconds = timeTo - timeFrom;

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    const hours = totalDays * diffInHours;
    const charge = hours * Number(values.hourly_rate);

    setSheetDetails((prev) => ({
      ...prev,
      hour: hours,
      charge: charge,
    }));

    return { hours, charge };
  };

  const totalHours = useCallback(() => {
    return values.timesheet?.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.hour);
    }, 0);
  }, [values?.timesheet]);

  // const totalCharge = useCallback(() => {
  //   return values.timesheet?.reduce((accumulator, currentItem) => {
  //     return accumulator + Number(currentItem.charge);
  //   }, 0);
  // }, [values?.timesheet]);

  const addTimeSheet = () => {
    if (check()) {
      if (getHoursnCharge()) {
        const result = getHoursnCharge();
        const newTimeSheet = [
          ...values.timesheet,
          {
            start_date: sheetDetails.date_from,
            end_date: sheetDetails.date_to,
            start_time: sheetDetails.time_from,
            end_time: sheetDetails.time_to,
            hour: result?.hours,
            charge: result?.charge,
          },
        ];
        setFieldValue("timesheet", newTimeSheet);

        setSheetDetails({
          date_from: "",
          date_to: "",
          time_from: "",
          time_to: "",
          hour: "",
          charge: "",
        });
      }
    }
  };

  const handleDelTimeSheet = (id) => {
    const updateRes = values.timesheet.filter((_, i) => i !== id);
    setFieldValue("timesheet", updateRes);
  };

  return (
    <div className="dashboard-modal">
      <h3>Locum Timesheet</h3>

      <div className="box-timesheet">
        <div className="box-timesheet-input-cover">
          <div className="fill-bg">
            <DateFrameInput
              label="Start and End Date"
              values={sheetDetails}
              setValues={setSheetDetails}
              error={errors}
            />
          </div>
          <div className="fill-bg">
            <TimeFrameInput
              label="Start and End Time"
              values={sheetDetails}
              setValues={setSheetDetails}
              error={errors}
            />
          </div>

          <div className="add-rows-btn">
            <CustomButton
              variant="outline"
              onClick={addTimeSheet}
              title="ADD"
              customStyles={{
                borderRadius: "0",
                height: "48px",
              }}
            />
          </div>
        </div>

        {values.timesheet?.length > 0 && (
          <div className="candidates-result">
            <div className="candidates-result-headings">
              <span>Start Date</span>
              <span>End Date</span>
              <span>Start Time</span>
              <span>End Time</span>
              <span>Hours</span>
              <span>Charges</span>
              <span>Action</span>
            </div>
            <div className="candidates-result-box">
              {values.timesheet?.map((item, i) => (
                <div key={i} className="single-candidate">
                  <div className="single">
                    <span className="mobile-time">Start Date:</span>
                    <span>{item.start_date}</span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">End Date:</span>
                    <span>{item.end_date}</span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">Start Time:</span>
                    <span>{item.start_time}</span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">End Time:</span>
                    <span>{item.end_time}</span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">Hours:</span>
                    <span>{item.hour}</span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">Charges:</span>
                    <span>
                      {_currency_format(
                        Number(item.hour * values.hourly_rate),
                        "NGN"
                      )}
                    </span>
                  </div>
                  <div className="single">
                    <span className="mobile-time">Action:</span>
                    <RiDeleteBinLine
                      style={{ cursor: "pointer" }}
                      color="red"
                      size="20"
                      onClick={() => handleDelTimeSheet(i)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 700,
                    color: "#465174",
                  }}
                >
                  Total Hours:
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 800,
                  }}
                >
                  {totalHours()}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 700,
                    color: "#465174",
                  }}
                >
                  Total Charge:
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 800,
                  }}
                >
                  {_currency_format(
                    Number(totalHours() * values.hourly_rate),
                    "NGN"
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="btn-bg">
        <div className="btn">
          <CustomButton
            title="Close"
            type="btn"
            onClick={close}
            background={"red"}
            border={"red"}
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              color: "#fff",
            }}
          />
        </div>

        <div className="btn">
          <CustomButton
            onClick={close}
            disable={!values.timesheet.length}
            title="Finish"
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#245293",
              color: "#fff",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FillTimeSheet;
