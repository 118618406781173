import { api } from "../BaseConfig";

export const dashboardSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobsAlert: builder.query({
      query: (data) => ({
        url: `/${data.acc_type}/job-alert`,
        method: "GET",
      }),
    }),
    getDashJobApplication: builder.query({
      query: (data) => ({
        url: `/${data.acc_type}/dashboard-application`,
        method: "GET",
      }),
    }),
    getApplicationCount: builder.query({
      query: (data) => ({
        url: `/${data.acc_type}/application-count`,
        method: "GET",
      }),
    }),
    getDashboardOverView: builder.query({
      query: (data) => ({
        url: `/${data.acc_type}/dashboard-card`,
        method: "GET",
      }),
    }),
    getJobsByStatus: builder.query({
      query: (data) => ({
        url: `organisation/${data.type}-jobs`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetJobsAlertQuery,
  useGetDashJobApplicationQuery,
  useGetApplicationCountQuery,
  useGetDashboardOverViewQuery,
  useGetJobsByStatusQuery,
} = dashboardSlice;
